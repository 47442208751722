<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        class="text-center"
      >
        <PassedQuizImageComponent v-show="score >= 75" />
        <FailedQuizImageComponent v-show="score < 75" />
      </v-col>
      <v-col
        cols="12"
        class="text-center"
      >
        <h6 class="heading heading-m-small result-main-title">
          {{ score >= 75 ? itemsResults[enumItemsResults.passed].title : itemsResults[enumItemsResults.failed].title }}
        </h6>
      </v-col>
      <v-col
        cols="12"
        class="text text-l-small-regular text-center result-main-message"
      >
        {{ score >= 75 ? itemsResults[enumItemsResults.passed].message : itemsResults[enumItemsResults.failed].message }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="specialties-chip-box py-2 my-2">
          <div class="mx-2">
            <p>{{ credits }} CME</p>
            <v-divider />
            <SpecialtiesComponent
              :keywords="specialtiesTitles"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-icon class="checkbox-analysis">
          mdi-checkbox-marked
        </v-icon>
        <span class="text text-l-small-regular result-text-score-analysis"> {{ answeredQuestions }}  ({{ score }}%)</span><br>
        <span class="text text-m-small-regular result-text-title-analysis">Question analysis</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="px-0"
      >
        <v-expansion-panels accordion>
          <v-expansion-panel
            v-for="(questionItem,index) in quiz"
            :key="index+uniqueKeysHelper()"
            cols="12"
          >
            <v-expansion-panel-header class="px-1">
              <template #default>
                <v-row no-gutters>
                  <v-col cols="2">
                    <QuizChipResultListComponent
                      :quiz-index="questionItem.index"
                      :question-status="questionItem.status"
                    />
                  </v-col>
                  <v-col
                    cols="8"
                    class="pl-2"
                  >
                    <span class="text text-l-large-bold">{{ questionItem.question }}</span>
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>{{ (quiz[index].answers[questionItem.currentAnsweredIndex] != undefined ? quiz[index].answers[questionItem.currentAnsweredIndex].description : '') }}</div>
              <div
                v-show="questionItem.status === 'failure' && canChangeAnswers"
                class="mt-2"
              >
                <v-btn
                  class="btn-change-answer text-uppercase"
                  elevation="0"
                  color="primary"
                  @click="changeAnswer(index)"
                >
                  <TakeQuizIconComponent left />&nbsp;
                  Change answer
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row v-if="enableGoToFeed">
      <v-col cols="12">
        <v-btn
          class="text text-l-small-bold btn-change-answer text-uppercase"
          elevation="0"
          color="primary"
          @click="$router.push('/')"
        >
          Go to feed
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import QuizChipResultListComponent from '@/components/quiz/QuizChipResultListComponent';
import SpecialtiesComponent from "@/components/common/SpecialtiesComponent";
import PassedQuizImageComponent from '@/assets/PassedQuizImageComponent';
import FailedQuizImageComponent from '@/assets/FailedQuizImageComponent';
import TakeQuizIconComponent from "@/assets/TakeQuizIconComponent";
import { v4 as uuidv4 } from 'uuid';
export default {
  components: { QuizChipResultListComponent, SpecialtiesComponent, PassedQuizImageComponent, FailedQuizImageComponent, TakeQuizIconComponent },
  props: ['quiz', 'articleSpecialties', 'credits', 'canChangeAnswers', 'enableGoToFeed'],
  data: () => ({
    uniqueKeysHelper: uuidv4,
    itemsResults: [
      { passed: true, title: "Congratulations", message: "You passed the test. You can now request a trascript to get your CME credits" },
      { passed: false, title: "Let's try again", message: "You didn't quite get the necessary correct responses to pass. No worries. You can still go to those questions and try to answer them correctly. Tip: We provide hints for finding the correct answer." }
    ],
    enumItemsResults: { passed: 0, failed: 1}
  }),
  computed: {
    score() {
      return Math.floor((this.quiz.reduce( (sum, currentQuestion) => {
        if (currentQuestion.status === 'success') {
          return sum + 1;
        }
        return sum + 0;
      }, 0) / this.quiz.length)*100);
    },
    answeredQuestions() {
      let result = 0;

      this.quiz.map( currentQuestion => {
        if (currentQuestion.status === 'success') {
          result = result + 1;
        }
      });

      return `${result} / ${this.quiz.length}`;
    },
    specialtiesTitles() {
      return this.articleSpecialties.map( specialty => specialty.title );
    }
  },
  methods: {
    changeAnswer(questionIndex) {
      this.$emit('change-answer', questionIndex);
    }
  }
}
</script>
<style scoped>
.v-expansion-panel-header {
  height: 80px;
}
.v-expansion-panel-content >>> div {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.v-expansion-panel::before {
  box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.04) !important;
}
.result-main-title {
  text-align: center;
  text-transform: uppercase;
}
.result-main-message {
  text-align: center;
}
.specialties-chip-box {
  border: 1px solid rgba(193, 193, 217, 0.27);
  box-sizing: border-box;
  border-radius: 5px;
}
.checkbox-analysis {
  color: #78909C;
}
.result-text-score-analysis {
  color: #92929D;
}
.result-text-title-analysis {
  letter-spacing: 2px !important;
  text-transform: uppercase;
  color: #828282;
}
.btn-change-answer {
  border-radius: 10px;
}
</style>
