<template>
  <div>
    <div class="text text-m-large-bold mx-2 theme-quiz-chip-list theme-quiz-chip-completed text-center clickable">
      {{ quizIndex + 1 }}
    </div>

    <v-icon
      :class="getDotStyle"
      class="theme-quiz-sub-chip"
    >
      {{ getIconStyle }}
    </v-icon>
  </div>
</template>

<script>
export default {
  props: ['quiz-index', 'question-status'],
  computed: {
    getDotStyle() {
      return this.questionStatus === "success" ? `theme-quiz-chip-${this.questionStatus}` : "theme-quiz-chip-failure";
    },
    getIconStyle() {
      return this.questionStatus === "success" ? "mdi-check" : "mdi-close";
    }
  },
}
</script>

<style scoped>
.theme-quiz-chip-list {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 2px solid;
  line-height: 1.7 !important;
}
.theme-quiz-sub-chip {
  position: relative;
  border-radius: 6px;
  top: -14px;
  left: 29px;
  padding: 3px 3px;
  font-size: 10px !important;
}
.theme-quiz-chip-completed {
  background-color: #2196F3;
  border-color: #2196F3;
  color: #FFFFFF;
}
.theme-quiz-chip-success {
  background-color: #69F0AE;
  border-color: #69F0AE;
  color: #FFFFFF;
}
.theme-quiz-chip-failure {
  background-color: #FF5252;
  border-color: #FF5252;
  color: #FFFFFF;
}
.theme-quiz-chip-current {
  background-color: #FFFFFF;
  border-color: #2196F3;
  color: #000000;
}
.theme-quiz-chip-default {
  background-color: #FFFFFF;
  border-color: #90A4AE;
  color: #000000;
}
.clickable:hover {
  cursor: pointer;
}
</style>
